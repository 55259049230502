import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AppBar, Drawer, Icon, IconButton, List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography, ThemeProvider, styled, Menu, MenuItem } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';
import { getCookieValue, changeLanguage } from './App';
import logo_loggedpng from './pics/logo_logged.png';
import flag_it from './pics/flag_it.svg';
import flag_en from './pics/flag_en.svg';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import { t } from './Util_format';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { markMessageAsRead, fetchPage } from './Util_API_calls';
import { theme } from './ReactStyles';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BuildIcon from '@mui/icons-material/Build';
import BusinessIcon from '@mui/icons-material/Business';
import config from './config/env.json';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import StorefrontIcon from '@mui/icons-material/Storefront';
import BarChartIcon from '@mui/icons-material/BarChart';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import BugReportIcon from '@mui/icons-material/BugReport';
import StorageIcon from '@mui/icons-material/Storage';
import WarningIcon from '@mui/icons-material/Warning';

function Structure_logged({ children, APIPageData = {} }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [drawerReduced, setDrawerReduced] = useState(false);
  const [open, setOpen] = useState(false);
  const [langMenuAnchorEl, setLangMenuAnchorEl] = useState(null);
  const [accountMenuAnchorEl, setAccountMenuAnchorEl] = useState(null);
  const currentFlag = getCookieValue('language') === 'en' ? flag_en : flag_it;
  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    const fetchUserRole = async () => {
      const result = await fetchPage('home');
      setUserRole(result.role);
      if (result.role === 'basic_investor' && (location.pathname !== '/home' && location.pathname !== '/customers' && location.pathname !== '/acquiring' && location.pathname !== '/accounts' && location.pathname !== '/banking-profits' && location.pathname !== '/store-sales' && location.pathname !== '/statistics')) {
        navigate('/home');
      } else if (result.role === 'call_centre' && (location.pathname !== '/home' && location.pathname !== '/customers' && location.pathname !== '/kyc-status')) {        
        navigate('/home');
      }
    };

    fetchUserRole();
  }, [navigate, location.pathname]);


  const handleLanguageChange = (lang) => {
    handleClose();
    changeLanguage(lang);
  };

  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

  useEffect(() => {

    const count = APIPageData?.messages
      ? APIPageData.messages.filter(msg => !msg.is_read).length
      : 0;

    setUnreadMessagesCount(count);
  }, [APIPageData]);

  const ListItemTextCickable = styled(ListItemText)(({ theme }) => ({
    '& .MuiListItemText-primary': {
      fontWeight: '500',
      color: '#637381',
      fontSize: '0.875rem',
      fontFamily: 'Poppins',
      textOverflow: 'ellipsis',
    },
    '&.reduced-text .MuiListItemText-primary': {
      fontSize: '0.7rem',
      textAlign: 'center',
    },
  }));

  const handleLangMenuClick = (event) => {
    setLangMenuAnchorEl(event.currentTarget);
  };

  const handleAccountMenuClick = (event) => {
    setAccountMenuAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setLangMenuAnchorEl(null);
    setAccountMenuAnchorEl(null);
  };

  const handleLogout = () => {
    navigate('/login?action=clean-cookie&reason=user_logout');
  };

  const handleGoToHome = () => {
    navigate('/home');
    setLangMenuAnchorEl(null);
    setAccountMenuAnchorEl(null);
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const ListItemIconStyled = styled(ListItemIcon)(({ theme }) => ({
    alignContent: 'center',
    minWidth: drawerReduced ? 'unset' : undefined,
  }));

  const StyledListItem = styled(ListItem)(({ theme }) => ({
    cursor: 'pointer',
    borderRadius: '5px',
    marginBottom: '5px',
    transition: 'background-color 0.3s ease',
    flexDirection: drawerReduced ? 'column' : 'row',
    justifyContent: drawerReduced ? 'center' : 'flex-start',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: '#edefef',
    },
    '&:active': {
      backgroundColor: '#c5cacf',
    },
  }));

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const TitleListItem = styled(ListItem)(({ theme }) => ({
    pointerEvents: 'none',
    userSelect: 'none',
    fontSize: '0.8rem',
    paddingBottom: '10px',
  }));

  const isMobile = useMediaQuery('(max-width:768px)');

  const contentStyles = isMobile
    ? {
      marginLeft: 0,
      width: 'calc(100vw - 40px)',
      paddingLeft: '20px',
      paddingRight: '20px',
      height: 'calc(100vh - 80px)',
      overflowY: 'auto',
      justifyContent: 'center !important',
      textAlign: 'center !important',
    }
    : {
      marginLeft: drawerReduced ? 100 : 260,
      width: drawerReduced ? 'calc(100vw - 140px)' : 'calc(100vw - 300px)',
      height: 'calc(100vh - 90px)',
      overflowY: 'auto',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#FFFFFF',
      paddingTop: '0px',
      paddingLeft: '20px',
      paddingRight: '20px'
    };


  useEffect(() => {
    const cookieRow = document.cookie.split('; ').find(row => row.startsWith('firebaseToken='));
    const cookieValue = cookieRow ? cookieRow.split('=')[1] : null;

    if (!cookieValue) {
      navigate('/login');
    } else {
      const loginOtpCompleted = getCookieValue('login_otp_completed');
      if (loginOtpCompleted !== 'true') {
        navigate("/login_otp");
      }
    }
  }, []);

  const [isMessagesModalOpen, setIsMessagesModalOpen] = useState(false);
  const [currentMessage, setCurrentMessage] = useState({ msg_id: null, subject: '', content: '' });


  const openMessagesModal = () => {
    setIsMessagesModalOpen(true);
  };


  const closeMessagesModal = () => {
    setIsMessagesModalOpen(false);
  };

  const handleReadMessage = (message) => {
    markMessageAsRead(message.msg_id);

    const updatedMessages = APIPageData.messages.map(msg =>
      msg.msg_id === message.msg_id ? { ...msg, is_read: true } : msg
    );

    setCurrentMessage({
      msg_id: message.msg_id,
      subject: message.subject,
      content: message.content
    });

    const newUnreadCount = updatedMessages.filter(msg => !msg.is_read).length;
    setUnreadMessagesCount(newUnreadCount);

    openMessagesModal();
  };

  const handleLogoClick = () => {
    if (userRole === 'call_centre') {
      navigate('/customers');
    } else {
      navigate('/home');
    }
  };


  return (
    <ThemeProvider theme={theme}>
      {isMobile && (
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleDrawerToggle}
          style={{
            position: 'absolute',
            backdropFilter: 'blur(10px)',
            top: '18px',
            left: '20px',
            zIndex: 1201
          }}
        >
          <MenuIcon />
        </IconButton>

      )}
      {!isMobile && (
        <IconButton
          edge="start"
          color="inherit"
          onClick={() => setDrawerReduced(!drawerReduced)}
          style={{
            position: 'absolute',
            top: '40px',
            transform: 'translateY(-50%) scale(0.5)',
            left: drawerReduced ? '90px' : '250px',
            border: '1px dotted #AAAAAA',
            borderRadius: '50%',
            width: '48px !important',
            height: '48px !important',
            zIndex: 1202,
            backgroundColor: '#FEFEFE',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {drawerReduced ?
            <ArrowForwardIcon style={{ fontSize: '24px' }} /> : <ArrowBackIcon style={{ fontSize: '24px' }} />
          }
        </IconButton>
      )}
      <AppBar position="static" style={{
        marginLeft: isMobile ? 0 : (drawerReduced ? 100 : 260),
        width: isMobile ? 'calc(100vw)' : (drawerReduced ? 'calc(100vw - 100px)' : 'calc(100vw - 260px)'),
        height: '80px', 
        overflow: 'hidden',
        paddingLeft: isMobile ? 60 : (drawerReduced ? 0 : 0)
      }}>
        <Toolbar style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '80px',
        }}
        >
          <IconButton
            style={{ visibility: 'hidden' }}
          >
            <SearchIcon />
          </IconButton>

          <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>

            <Typography
              variant="h6"
              style={{
                marginRight: '5rem',
                color: 'black',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {(() => {
                switch (userRole) {
                  case 'basic_investor':
                    return 'Investitore';
                  case 'call_centre':
                    return 'Helpdesk User';
                  case 'super_admin':
                    return (
                      <span>
                        Super Admin <span style={{ color: '#4db6ac', fontWeight: 'bold' }}> | {config.FDT_BRAND_CODE.toUpperCase()}</span>
                      </span>
                    );
                  default:
                    return 'Loading....';
                }
              })()}
            </Typography>

            {/* Language Menu */}
            <IconButton onClick={handleLangMenuClick}>
              <img src={currentFlag} alt="Italiano" style={{ width: '28px', height: '28px', borderRadius: '10px' }} />
            </IconButton>
            <Menu
              anchorEl={langMenuAnchorEl}
              keepMounted
              open={Boolean(langMenuAnchorEl)}
              onClose={handleClose}
              PaperProps={{
                style: {
                  borderRadius: 8,
                  marginTop: '10px'
                },
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              {[
                { flag: flag_it, alt: "Italiano", lang: "it" },
                { flag: flag_en, alt: "English", lang: "en" }
              ].map(item => (
                <MenuItem
                  key={item.lang}
                  onClick={() => handleLanguageChange(item.lang)}
                  style={{
                    fontWeight: '500',
                    color: '#637381',
                    fontSize: '0.875rem',
                    fontFamily: 'Poppins',
                    textOverflow: 'ellipsis'
                  }}
                >
                  <img src={item.flag} alt={item.alt} style={{ width: '28px', height: '20px', marginRight: '10px' }} />
                  {item.alt}
                </MenuItem>
              ))}
            </Menu>

            {/* Notifications */}
            <IconButton onClick={toggleDrawer(true)}>
              <Badge badgeContent={unreadMessagesCount} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
              <div
                role="presentation"
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
              >
                <div style={{ padding: '20px' }}>
                  <Typography className='notifications_title'>
                    {t('notifications')}
                  </Typography>
                  {
                    APIPageData?.messages && APIPageData.messages.length > 0 ? (
                      APIPageData.messages.map((message, index) => {



                        return (
                          <div
                            className="notifications_list_with_border"
                            key={index}
                            onClick={() => handleReadMessage(message)}
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <Icon style={{ color: message.is_read ? '#f0f0f0' : '#339AD8', marginRight: '8px' }}>
                              {message.is_read ? <MailIcon /> : <MailOutlineIcon />}
                            </Icon>
                            {message.subject} e {message.is_read.toString()}
                          </div>
                        );
                      })
                    ) : (
                      <Typography className='no_notifications'>
                        {t('no_notifications')}
                      </Typography>
                    )
                  }
                </div>

              </div>
            </Drawer>
            {/* Account Menu */}
            <IconButton onClick={handleAccountMenuClick}>
              <AccountCircleIcon style={{ width: '28px', height: '28px', borderRadius: '5px' }} color="primary" />
            </IconButton>
            <Menu
              anchorEl={accountMenuAnchorEl}
              keepMounted
              open={Boolean(accountMenuAnchorEl)}
              onClose={handleClose}
              PaperProps={{
                style: {
                  borderRadius: 8,
                  marginTop: '10px'
                },
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <MenuItem onClick={handleGoToHome} style={{
                fontWeight: '500',
                color: '#637381',
                fontSize: '0.875rem',
                fontFamily: 'Poppins',
                textOverflow: 'ellipsis',
              }}
              >
                <HomeIcon color="primary" style={{ paddingRight: '10px' }} />
                {t("dashboard")}
              </MenuItem>

              <MenuItem onClick={handleLogout}
                style={{
                  fontWeight: '500',
                  color: '#637381',
                  fontSize: '0.875rem',
                  fontFamily: 'Poppins',
                  textOverflow: 'ellipsis'
                }}
              >
                <LogoutIcon color="primary" style={{ paddingRight: '10px' }} />
                {t("logout")}
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>

      </AppBar>
      <div style={{ ...contentStyles }}>
        {children}
      </div>

      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        open={isMobile ? open : true}
        onClose={handleDrawerToggle}
        className={drawerReduced ? "reduced-drawer" : ""}
        classes={{
          paper: drawerReduced ? "reduced-drawer" : "",
        }}
        style={{ padding: '5px' }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <div role="presentation" onClick={toggleDrawer} onKeyDown={toggleDrawer}>

            <div role="presentation" onClick={toggleDrawer} onKeyDown={toggleDrawer}>
              <List>
                {/* Logo section - unchanged */}
                <ListItem
                  style={{
                    justifyContent: 'center',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    userSelect: 'none',
                    pointerEvents: 'auto',
                    backgroundColor: 'transparent',
                    '&:hover': {
                      backgroundColor: 'transparent'
                    }
                  }}
                >
                  <img
                    src={logo_loggedpng}
                    alt="Company Logo"
                    style={{
                      width: '80px',
                      height: '80px',
                      cursor: 'pointer'
                    }}
                    onClick={handleLogoClick}
                  />
                </ListItem>

                {/* Management Panels Title */}
                <TitleListItem>
                  {!drawerReduced && (
                    <ListItemText
                      primary="PANNELLI DI GESTIONE"
                      sx={{ fontWeight: 'bold', color: '#339AD8', fontSize: '8px' }}
                    />
                  )}
                </TitleListItem>

                {/* Main navigation items */}

                {/* Home Section */}
                <StyledListItem
                  onClick={() => { navigate('/home'); }}
                  style={location.pathname.startsWith('/home') ? { backgroundColor: '#e0f0f9' } : {}}
                >
                  <ListItemIconStyled><HomeIcon color="primary" /></ListItemIconStyled>
                  {!drawerReduced ? (
                    <ListItemTextCickable primary={t("home")} />
                  ) : (
                    <ListItemTextCickable className="reduced-text" primary={t("home")} />
                  )}
                </StyledListItem>

                {/* Customer Service Section */}
                {(userRole === 'super_admin' || userRole === 'basic_investor' || userRole === 'call_centre') && (
                  <StyledListItem
                    onClick={() => { navigate('/customers'); }}
                    style={location.pathname.startsWith('/customers') ? { backgroundColor: '#e0f0f9' } : {}}
                  >
                    <ListItemIconStyled><SupportAgentIcon color="primary" /></ListItemIconStyled>
                    {!drawerReduced ? (
                      <ListItemTextCickable primary={t("customers")} />
                    ) : (
                      <ListItemTextCickable className="reduced-text" primary={t("customers")} />
                    )}
                  </StyledListItem>
                )}

                {(userRole === 'super_admin' || userRole === 'basic_investor') && (
                  <StyledListItem
                    onClick={() => { navigate('/acquiring'); }}
                    style={location.pathname.startsWith('/acquiring') ? { backgroundColor: '#e0f0f9' } : {}}
                  >
                    <ListItemIconStyled><CreditCardIcon color="primary" /></ListItemIconStyled>
                    {!drawerReduced ? (
                      <ListItemTextCickable primary={t("acquiring")} />
                    ) : (
                      <ListItemTextCickable className="reduced-text" primary={t("acquiring")} />
                    )}
                  </StyledListItem>
                )}
                {(userRole === 'super_admin' || userRole === 'basic_investor') && (
                  <StyledListItem
                    onClick={() => { navigate('/accounts'); }}
                    style={location.pathname.startsWith('/accounts') ? { backgroundColor: '#e0f0f9' } : {}}
                  >
                    <ListItemIconStyled><AccountBalanceWalletIcon color="primary" /></ListItemIconStyled>
                    {!drawerReduced ? (
                      <ListItemTextCickable primary={t("accounts_menu")} />
                    ) : (
                      <ListItemTextCickable className="reduced-text" primary={t("accounts_menu")} />
                    )}
                  </StyledListItem>
                )}
                {(userRole === 'super_admin' || userRole === 'basic_investor') && (
                  <StyledListItem
                    onClick={() => { navigate('/banking-profits'); }}
                    style={location.pathname.startsWith('/banking-profits') ? { backgroundColor: '#e0f0f9' } : {}}
                  >
                    <ListItemIconStyled><TrendingUpIcon color="primary" /></ListItemIconStyled>
                    {!drawerReduced ? (
                      <ListItemTextCickable primary={t("banking-profits")} />
                    ) : (
                      <ListItemTextCickable className="reduced-text" primary={t("banking-profits")} />
                    )}
                  </StyledListItem>
                )}
                {(userRole === 'super_admin' || userRole === 'basic_investor') && (
                  <StyledListItem
                    onClick={() => { navigate('/store-sales'); }}
                    style={location.pathname.startsWith('/store-sales') ? { backgroundColor: '#e0f0f9' } : {}}
                  >
                    <ListItemIconStyled><StorefrontIcon color="primary" /></ListItemIconStyled>
                    {!drawerReduced ? (
                      <ListItemTextCickable primary={t("store-sales")} />
                    ) : (
                      <ListItemTextCickable className="reduced-text" primary={t("store-sales")} />
                    )}
                  </StyledListItem>
                )}
                {(userRole === 'super_admin' || userRole === 'basic_investor') && (
                  <StyledListItem
                    onClick={() => { navigate('/statistics'); }}
                    style={location.pathname.startsWith('/statistics') ? { backgroundColor: '#e0f0f9' } : {}}
                  >
                    <ListItemIconStyled><BarChartIcon color="primary" /></ListItemIconStyled>
                    {!drawerReduced ? (
                      <ListItemTextCickable primary={t("statistics")} />
                    ) : (
                      <ListItemTextCickable className="reduced-text" primary={t("statistics")} />
                    )}
                  </StyledListItem>
                )}

                {/* Admin Section Title */}
                
                <TitleListItem>
                  {!drawerReduced && (userRole === 'super_admin' || userRole === 'basic_investor') && (
                    <ListItemText
                      primary={t('super_admin_menus')}
                      sx={{ fontWeight: 'bold', color: '#339AD8', fontSize: '8px' }}
                    />
                  )}
                </TitleListItem>

                {/* Admin Section Items */}
                {userRole === 'super_admin' && (
                  <StyledListItem
                    onClick={() => { navigate('/troubleshooting'); }}
                    style={location.pathname.startsWith('/troubleshooting') ? { backgroundColor: '#e0f0f9' } : {}}
                  >
                    <ListItemIconStyled><BugReportIcon color="primary" /></ListItemIconStyled>
                    {!drawerReduced ? (
                      <ListItemTextCickable primary={t("troubleshooting")} />
                    ) : (
                      <ListItemTextCickable className="reduced-text" primary={t("troubleshooting")} />
                    )}
                  </StyledListItem>
                )}

                {userRole === 'super_admin' && (
                  <StyledListItem
                    onClick={() => { navigate('/suppliers'); }}
                    style={location.pathname.startsWith('/suppliers') ? { backgroundColor: '#e0f0f9' } : {}}
                  >
                    <ListItemIconStyled><BusinessIcon color="primary" /></ListItemIconStyled>
                    {!drawerReduced ? (
                      <ListItemTextCickable primary={t("suppliers_services_status")} />
                    ) : (
                      <ListItemTextCickable className="reduced-text" primary={t("suppliers")} />
                    )}
                  </StyledListItem>
                )}

                {userRole === 'super_admin' && (
                  <StyledListItem
                    onClick={() => { navigate('/infrastructure-status'); }}
                    style={location.pathname.startsWith('/infrastructure-status') ? { backgroundColor: '#e0f0f9' } : {}}
                  >
                    <ListItemIconStyled><StorageIcon color="primary" /></ListItemIconStyled>
                    {!drawerReduced ? (
                      <ListItemTextCickable primary={t("infrastructure-status")} />
                    ) : (
                      <ListItemTextCickable className="reduced-text" primary={t("infrastructure-status")} />
                    )}
                  </StyledListItem>
                )}

                {userRole === 'super_admin' && (
                  <StyledListItem
                    onClick={() => { navigate('/super-admin-operations'); }}
                    style={location.pathname.startsWith('/super-admin-operations') ? { backgroundColor: '#e0f0f9' } : {}}
                  >
                    <ListItemIconStyled><WarningIcon color="primary" /></ListItemIconStyled>
                    {!drawerReduced ? (
                      <ListItemTextCickable primary={t("emergency_ops")} />
                    ) : (
                      <ListItemTextCickable className="reduced-text" primary={t("super-admin-operations")} />
                    )}
                  </StyledListItem>
                )}

                {userRole === 'super_admin' && (
                  <StyledListItem
                    onClick={() => { navigate('/master-account'); }}
                    style={location.pathname.startsWith('/master-account') ? { backgroundColor: '#e0f0f9' } : {}}
                  >
                    <ListItemIconStyled><AccountBalanceIcon color="primary" /></ListItemIconStyled>
                    {!drawerReduced ? (
                      <ListItemTextCickable primary={t("master-account")} />
                    ) : (
                      <ListItemTextCickable className="reduced-text" primary={t("master-account")} />
                    )}
                  </StyledListItem>
                )}

                {userRole === 'super_admin' && (
                  <StyledListItem
                    onClick={() => { navigate('/maintenance'); }}
                    style={location.pathname.startsWith('/maintenance') ? { backgroundColor: '#e0f0f9' } : {}}
                  >
                    <ListItemIconStyled><BuildIcon color="primary" /></ListItemIconStyled>
                    {!drawerReduced ? (
                      <ListItemTextCickable primary={t("Manutenzione")} />
                    ) : (
                      <ListItemTextCickable className="reduced-text" primary={t("Manutenzione")} />
                    )}
                  </StyledListItem>
                )}

                {userRole === 'super_admin' && (
                  <TitleListItem>
                    {!drawerReduced && (
                      <ListItemText
                        primary={t('BUSINESS')}
                        sx={{ fontWeight: 'bold', color: '#339AD8', fontSize: '8px' }}
                      />
                    )}
                  </TitleListItem>
                )}

                {(userRole === 'super_admin' || userRole === 'call_centre') && (
                  <StyledListItem
                    onClick={() => { navigate('/kyc-status'); }}
                    style={location.pathname.startsWith('/kyc-status') ? { backgroundColor: '#e0f0f9' } : {}}
                  >
                    <ListItemIconStyled><VerifiedUserIcon color="primary" /></ListItemIconStyled>
                    {!drawerReduced ? (
                      <ListItemTextCickable primary={t("kyc-status")} />
                    ) : (
                      <ListItemTextCickable className="reduced-text" primary={t("kyc-status")} />
                    )}
                  </StyledListItem>
                )}
              </List>
            </div>
          </div>
        </div>
      </Drawer>
    </ThemeProvider>

  );
}

export default Structure_logged;
