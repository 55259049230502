import React from 'react';
import {ThemeProvider, Box, Typography
} from '@mui/material';
import { theme } from './ReactStyles';

function Page_Home({  }) {

  return (
    <div style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '1500px', paddingTop: '50px', textAlign: 'center' }}>
      <ThemeProvider theme={theme}>
        <Box>
          <Typography variant="h4" sx={{ color: '#339AD8', fontSize: '20px' }}>
          Qua dentro inseriremo le informazioni più importanti da visualizzare immediatamente post accesso.<br></br><br></br>Discutiamo assieme su cosa sia più importante da visualizzare. Devono esere informazioni di alto livello.
          </Typography>
        </Box>
      </ThemeProvider>
    </div>
  );
}

export default Page_Home;