import React, { useState, useRef } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, CircularProgress, Typography, Box } from '@mui/material';
import { sendOTPDoubleAuth, verifyOTPDoubleAuth, enableCustomer, disableCustomer } from './Util_API_calls';

const Modal_UnableDisable = ({ isOpen, onClose, customer_id }) => {
  const [otp, setOtp] = useState(Array(6).fill(''));
  const [isProcessing, setIsProcessing] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const inputsRef = useRef([]);

  const handleSendOTP = async () => {
    setIsProcessing(true);
    setErrorMessage('');
    setSuccessMessage('');
    try {
      const response = await sendOTPDoubleAuth('sms');
      if (response.status === 'success') {
        setSuccessMessage('OTP sent successfully');
      } else {
        setErrorMessage('Error sending OTP');
      }
    } catch (error) {
      setErrorMessage('Error sending OTP');
    } finally {
      setIsProcessing(false);
    }
  };
  const handleVerifyOTP = async () => {
    setIsProcessing(true);
    setErrorMessage('');
    setSuccessMessage('');
    try {
      const otpCode = otp.join('');
      const response = await verifyOTPDoubleAuth(otpCode);
      if (response.status === 'success') {
        setOtpVerified(true);
        setSuccessMessage('OTP verified successfully');
      } else {
        setErrorMessage(response.details || 'Error verifying OTP');
      }
    } catch (error) {
      setErrorMessage('Error verifying OTP: ' + error.message);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleCustomerAction = async (action) => {
    setIsProcessing(true);
    setErrorMessage('');
    setSuccessMessage('');
    try {
      let response;
      if (action === 'enable') {
        response = await enableCustomer(customer_id);
      } else if (action === 'disable') {
        response = await disableCustomer(customer_id);
      }

      if (response.status === 'success') {
        setSuccessMessage(`Customer ${action === 'enable' ? 'enabled' : 'disabled'} successfully`);
      } else {
        setErrorMessage(response.message || `Error ${action === 'enable' ? 'enabling' : 'disabling'} customer`);
      }
    } catch (error) {
      setErrorMessage(`Error ${action === 'enable' ? 'enabling' : 'disabling'} customer: ${error.message}`);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleChange = (index, value) => {
    if (!/^[0-9]$/.test(value)) return;
    setOtp(prevOtp => {
      const newOtp = [...prevOtp];
      newOtp[index] = value;
      return newOtp;
    });
    setTimeout(() => {
      if (index < otp.length - 1) {
        inputsRef.current[index + 1] && inputsRef.current[index + 1].focus();
      }
    }, 10);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      e.preventDefault();
      if (otp[index] === '') {
        const prevIndex = index - 1;
        if (prevIndex >= 0) {
          setOtp(prevOtp => {
            const newOtp = [...prevOtp];
            newOtp[prevIndex] = '';
            return newOtp;
          });
          setTimeout(() => {
            inputsRef.current[prevIndex].focus();
          }, 0);
        }
      } else {
        setOtp(prevOtp => {
          const newOtp = [...prevOtp];
          newOtp[index] = '';
          return newOtp;
        });
        setTimeout(() => {
          inputsRef.current[index].focus();
        }, 0);
      }
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Abilita/Disabilita Cliente</DialogTitle>
      <DialogContent>
        {errorMessage && (
          <Box color="error.main" mb={2}>
            <Typography>{errorMessage}</Typography>
          </Box>
        )}
        {successMessage && (
          <Box color="success.main" mb={2}>
            <Typography>{successMessage}</Typography>
          </Box>
        )}
        {!otpVerified && (
          <>
            <Box mb={2}>
              <Button onClick={handleSendOTP} variant="contained" color="primary" disabled={isProcessing}>
                {isProcessing ? <CircularProgress size={24} /> : 'Send OTP'}
              </Button>
            </Box>
            <Box display="flex" justifyContent="center">
              {otp.map((digit, index) => (
                <TextField
                  key={index}
                  variant="outlined"
                  inputProps={{
                    maxLength: 1,
                    style: { textAlign: 'center' }
                  }}
                  inputRef={el => inputsRef.current[index] = el}
                  value={digit}
                  onChange={(e) => handleChange(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                />
              ))}
            </Box>
            <Box mt={2}>
              <Button onClick={handleVerifyOTP} variant="contained" color="primary" disabled={isProcessing}>
                {isProcessing ? <CircularProgress size={24} /> : 'Verifica OTP'}
              </Button>
            </Box>
          </>
        )}
        {otpVerified && (
          <Box mt={2}>
            <Typography>OTP Verified. You can now enable or disable the customer.</Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        {otpVerified && (
          <>
            <Button onClick={() => handleCustomerAction('enable')} variant="contained" color="primary" disabled={isProcessing}>
              {isProcessing ? <CircularProgress size={24} /> : 'Abilita'}
            </Button>
            <Button onClick={() => handleCustomerAction('disable')} variant="contained" color="secondary" disabled={isProcessing}>
              {isProcessing ? <CircularProgress size={24} /> : 'Disabilita'}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default Modal_UnableDisable;
